import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueGtm from "@gtm-support/vue2-gtm";

Vue.config.productionTip = false;

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    enabled: process.env.NODE_ENV === "production",
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ["error", "disabled", "banned"],
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
